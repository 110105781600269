jQuery(document).ready(function( $ ) {
	
	$(document).foundation();
	
	/*
	These functions make sure WordPress
	and Foundation play nice together.
	*/
	
	
    // Remove empty P tags created by WP inside of Accordion and Orbit
    $('.accordion p:empty, .orbit p:empty').remove();

	// Adds Flex Video to YouTube and Vimeo Embeds
	$('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( $(this).innerWidth() / $(this).innerHeight() > 1.5 ) {
		  $(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
		  $(this).wrap("<div class='responsive-embed'/>");
		}
	});
	
	// custom code here
	$('.slick-carousel').slick();
	
	// Prevents .slick-track width of 0 for Foundation Reveal
	$(document).on('closed.zf.reveal', '#internships-testimonials[data-reveal]', function() {
		if (typeof($('.internships-carousel').slick) === 'function') {
			$('.internships-carousel').slick('unslick');
		}
	});
	$(document).on('open.zf.reveal', '#internships-testimonials[data-reveal]', function() {
		$('.internships-carousel').slick({
			arrows: true,
			dots: true
		});
	});
	
	$('.meet-the-team-carousel').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: false,
		responsive: [{
		  breakpoint: 800,
		  settings: "unslick"
		}]
	});
	
	$('.history-carousel').slick({
		slidesToShow: 2,
		slidesToScroll: 1,
		infinite: false,
		responsive: [{
		  breakpoint: 800,
		  settings: "unslick"
		}]
	});
	
	$('.featured-work-carousel').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		responsive: [{
		  breakpoint: 800,
		  settings: "unslick"
		}]
	});
    
    function reinitCareerPathCarousel() {
        var target = '';
        target = $('.accordion-item.is-active > .accordion-title').attr('href'); // try accordion
        if ('undefined' === typeof(target)) {  target = $('.tabs-title.is-active > a').attr('href'); } // try tabs
        if ('undefined' !== typeof(target)) {
            console.log(target);
            var carousel = $(target).find('.carousel-people');
            if (carousel.length >0) {
                if ($(carousel).hasClass('slick-initialized')) {  $(carousel).slick('unslick');  }
                $(carousel).slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                    infinite: true,
                });
            }
        }
    }
    // resize carousel on accordion change
    $('.path-nav').on('change.zf.tabs', function(event, tab) {  reinitCareerPathCarousel();  });
    $('.path-nav').on('down.zf.accordion', function(event, pane) {  reinitCareerPathCarousel();  });
	
	// SCROLLA
	$('.animate').scrolla({
		mobile: false // disable animation on mobiles 
	});
	
	
});